import React, { useState, useCallback } from 'react';
import VideoPlayerManager from './VideoPlayerManager';

interface VideoPlayerProps {
  url: string;
  start: number;
  end: number;
  podcastName: string;
  episodeName: string;
  clipDuration: number;
  onToggleFullEpisode: () => void; 
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  url,
  start,
  end,
  podcastName,
  episodeName,
  clipDuration,
  onToggleFullEpisode, 
}) => {
  const [isFullEpisode, setIsFullEpisode] = useState(false);

  const handleToggleFullEpisode = useCallback(() => {
    setIsFullEpisode((prev) => !prev);
  }, []);

  return (
    <div className="video-player-container rounded-lg overflow-hidden relative transition-all duration-500 ease-in-out hover:transform hover:scale-105 hover:z-10"
         style={{
           boxShadow: '0 0 20px 5px rgba(255, 255, 255, 0.3)',
           transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
         }}
    >
      <div className="absolute inset-0 transition-opacity duration-500 ease-in-out opacity-0 hover:opacity-100"
           style={{
             boxShadow: 'inset 0 0 40px 20px rgba(255, 255, 255, 0.5), 0 0 50px 25px rgba(255, 255, 255, 0.5)',
             transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
           }}
      ></div>
      <VideoPlayerManager
        url={url}
        start={start}
        end={end}
        isFullEpisode={isFullEpisode}
        onToggleFullEpisode={handleToggleFullEpisode}
        clipDuration={clipDuration}
        podcastName={podcastName}
        episodeName={episodeName}
      />
    </div>
  );
};

export default VideoPlayer;