import React, { useCallback } from 'react';
import Link from 'next/link';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { useTransition } from 'react';
import clsx from 'clsx';

// Separate styles into a dedicated constants file or CSS module
const HERO_STYLES = {
  section: `
    relative
    flex flex-col items-center justify-center 
    min-h-[50vh] 
    bg-transparent text-white
    px-4 md:px-6 lg:px-8
    motion-safe:animate-gradient-background
  `,
  heading: `
    text-4xl md:text-5xl lg:text-6xl 
    font-bold mb-10 
    text-center
    glow-text
    motion-safe:animate-float
  `,
  button: {
    base: `
      px-6 md:px-8 py-3 md:py-4 
      text-lg md:text-xl font-semibold 
      text-white bg-transparent 
      border-2 border-white rounded-none 
      transition-all duration-500 ease-out
      focus:outline-none focus:ring-4 focus:ring-white/50 
      shadow-[0_0_20px_5px_rgba(0,191,255,0.3)]
      relative overflow-hidden
      group
    `,
    interactive: `
      hover:border-transparent
      hover:shadow-[0_0_30px_10px_rgba(0,191,255,0.5)]
      hover:scale-105
      active:scale-95
    `,
    loading: `
      cursor-wait 
      opacity-80
      pointer-events-none
      animate-pulse
    `
  }
} as const;

// Move types to a separate types file
interface PodcastHeroProps {
  className?: string;
}

// Add these type definitions at the top of the file
type SessionStatus = 'authenticated' | 'unauthenticated' | 'loading';

interface ButtonState {
  text: string;
  ariaLabel: string;
}

// Improve type safety and maintainability with a proper state map
const HERO_CONTENT = {
  HEADING: 'Designed For Who Wants To Be Seen',
  BUTTON_STATES: {
    authenticated: {
      text: 'Explore Topics',
      ariaLabel: 'Explore podcast topics'
    },
    unauthenticated: {
      text: 'The New Age of Video Sharing Starts Here',
      ariaLabel: 'Sign up for access'
    },
    loading: {
      text: 'Loading...',
      ariaLabel: 'Please wait while loading'
    }
  } satisfies Record<SessionStatus, ButtonState>
} as const;

/**
 * PodcastHero Component
 * @description Hero section for the podcast landing page with dynamic CTA based on user session
 */
const PodcastHero: React.FC<PodcastHeroProps> = ({ className }) => {
  const { data: session, status } = useSession();
  const router = useRouter();
  const [isPending, startTransition] = useTransition();

  const handleButtonClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (status === 'loading') return;
    
    startTransition(() => {
      router.push(session ? '/topics' : '/sign-up');
    });
  }, [session, router, status]);

  const currentState: SessionStatus = isPending ? 'loading' : status;
  const buttonState = HERO_CONTENT.BUTTON_STATES[currentState];
  
  const buttonClassName = clsx(
    HERO_STYLES.button.base,
    currentState !== 'loading' && HERO_STYLES.button.interactive,
    currentState === 'loading' && HERO_STYLES.button.loading
  );

  return (
    <section 
      className={clsx(HERO_STYLES.section, className)}
      aria-labelledby="hero-heading"
    >
      <div className="w-full max-w-4xl mx-auto">
        <h1 
          id="hero-heading"
          className={HERO_STYLES.heading}
        >
          {currentState === 'loading' ? (
            <span className="animate-pulse">{HERO_CONTENT.HEADING}</span>
          ) : (
            HERO_CONTENT.HEADING
          )}
        </h1>
        <div className="flex justify-center">
          <button
            onClick={handleButtonClick}
            className={buttonClassName}
            disabled={currentState === 'loading'}
            aria-busy={currentState === 'loading'}
            aria-label={buttonState.ariaLabel}
          >
            <span className="relative z-10 transition-colors duration-500 group-hover:text-black">
              {currentState === 'loading' ? (
                <span className="inline-flex items-center gap-2">
                  {buttonState.text}
                  <LoadingSpinner className="w-4 h-4" />
                </span>
              ) : (
                buttonState.text
              )}
            </span>
            <span 
              className="
                absolute inset-0 
                bg-gradient-to-r from-white via-white to-[#f0f8ff]
                transform scale-x-0 origin-left 
                transition-transform duration-500 ease-out
                group-hover:scale-x-100
              "
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </section>
  );
};

// Separate loading spinner component
const LoadingSpinner: React.FC<{ className?: string }> = ({ className }) => (
  <svg 
    className={clsx("animate-spin", className)} 
    xmlns="http://www.w3.org/2000/svg" 
    fill="none" 
    viewBox="0 0 24 24"
  >
    <circle 
      className="opacity-25" 
      cx="12" 
      cy="12" 
      r="10" 
      stroke="currentColor" 
      strokeWidth="4"
    />
    <path 
      className="opacity-75" 
      fill="currentColor" 
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

export default PodcastHero;
