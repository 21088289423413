import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useRouter } from 'next/navigation';
import dynamic from 'next/dynamic';
import { ONBOARDING_MESSAGES } from '../constants/onboarding-messages';

const TypeWriter = dynamic(() => import('react-typewriter-effect'), { ssr: false });

interface ProfileSetupCardProps {
  isVisible: boolean;
}

const calculateReadingTime = (text: string): number => {
  const wordsPerMinute = 200;
  const words = text.split(' ').length;
  return (words / wordsPerMinute) * 60 * 1000; // Convert to milliseconds
};

const ProfileSetupCard: React.FC<ProfileSetupCardProps> = ({ isVisible }) => {
  const router = useRouter();
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [showButton, setShowButton] = useState(false);
  const messages = Object.values(ONBOARDING_MESSAGES);
  const currentMessage = messages[currentMessageIndex];

  useEffect(() => {
    if (!isVisible || currentMessageIndex >= messages.length - 1) return;

    const message = messages[currentMessageIndex];
    const typingTime = message.content.length * 50; // 50ms per character
    const readingTime = calculateReadingTime(message.content);
    
    const timer = setTimeout(() => {
      setCurrentMessageIndex(prev => prev + 1);
    }, typingTime + readingTime + message.delayAfter);

    // Handle action timing
    if (message.action) {
      const actionTimer = setTimeout(() => {
        if (message.action?.type === 'showButton') {
          setShowButton(true);
        }
      }, message.action.delay);

      return () => {
        clearTimeout(timer);
        clearTimeout(actionTimer);
      };
    }

    return () => clearTimeout(timer);
  }, [currentMessageIndex, messages, isVisible]);

  if (!isVisible) return null;

  return (
    <section className="bg-black text-white py-8 sm:py-16 px-4 sm:px-8">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-start justify-between">
        <motion.div 
          className="rounded-lg border border-zinc-800 bg-transparent p-8 backdrop-blur-lg md:w-1/2 mb-8 md:mb-0"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <div className="space-y-6">
            {messages.slice(0, currentMessageIndex + 1).map((message, index) => (
              <motion.div 
                key={index}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.5 }}
              >
                {index === currentMessageIndex ? (
                  <TypeWriter
                    text={`${message.sender}: ${message.content}`}
                    cursorColor="white"
                    typeSpeed={50}
                  />
                ) : (
                  <div>{`${message.sender}: ${message.content}`}</div>
                )}
              </motion.div>
            ))}
          </div>
        </motion.div>

        <div className="md:w-1/2 md:pl-8 flex flex-col items-center md:items-start">
          <h3 className="text-xs font-medium tracking-[0.2em] text-pink-500/90 uppercase mb-6">How MELO works</h3>
          <h2 className="text-4xl lg:text-5xl font-[350] leading-tight tracking-[-0.02em] mb-6 text-center md:text-left">Get Personalized Video Recommendations</h2>
          <p className="text-gray-300 text-lg leading-relaxed mb-8 text-center md:text-left">
            MELO's intelligent profile setup engine tailors your video experience to your unique interests and preferences.
          </p>
          
          <motion.div 
            className="mt-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: showButton ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          >
            {showButton && (
              <button 
                className="bg-transparent text-white px-6 py-3 rounded-lg border border-white hover:bg-white hover:text-black transition duration-300 shadow-[0_0_15px_rgba(0,100,255,0.5)] hover:shadow-[0_0_20px_rgba(0,100,255,0.8)]"
                onClick={() => router.push('/sign-up')}
              >
                Set Up Your Profile
              </button>
            )}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default ProfileSetupCard;
