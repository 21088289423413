export interface OnboardingMessage {
  sender: 'MELO' | 'USER';
  content: string;
  delayAfter: number; // milliseconds to wait after message
  type: 'greeting' | 'question' | 'example' | 'response';
  action?: {
    type: 'showButton' | 'enableInput';
    delay: number;
  };
}

export const ONBOARDING_MESSAGES: Record<string, OnboardingMessage> = {
  welcome: {
    sender: 'MELO',
    type: 'greeting',
    content: "hey there! welcome to melo! we're excited to help you discover video conversations, publish your own podcast, and connect with like-minded people.",
    delayAfter: 2000,
    action: {
      type: 'showButton',
      delay: 1000
    }
  },
  nameLocation: {
    sender: 'MELO',
    type: 'question',
    content: "what's your full name, and where are you based?",
    delayAfter: 3000,
  },
  exampleResponse: {
    sender: 'USER',
    type: 'example',
    content: "hi, i'm chloe levalley from san francisco",
    delayAfter: 1500,
  },
} as const;
export type OnboardingMessageKey = keyof typeof ONBOARDING_MESSAGES;

