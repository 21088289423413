import { ObjectId } from 'mongodb';

// types.ts

export enum VideoUploadSteps {
    EpisodeName = "EpisodeName",
    EpisodeDescription = "EpisodeDescription",
    VideoLocation = "VideoLocation",
    EpisodeParticipants = "EpisodeParticipants",
    VideoUpload = "VideoUpload"
  }
  
  export interface Participant {
    name: string;
    roles: string[]; 
    occupation?: string;
    experience?: string;
    socialMedia?: Record<string, string>;
  }

  export type EpisodeParticipants = Participant[];

  export interface Sponsor {
    name: string;
    category?: string;
    products?: string[];
    targetDemographic?: string;
  }

  export interface EpisodeInfo {
    _id?: ObjectId;
    userId: string;
    name: string;
    description: string;
    videoLocation: string;
    recordingDetails: {
      neighborhood?: string;
      city?: string;
      state?: string;
      country?: string;
      landmark?: string;
      location: string;
    };
    participants?: EpisodeParticipants;
    createdAt: Date;
    updatedAt: Date;
    status: 'in_progress' | 'completed';
    steps: {
      [key in VideoUploadSteps]?: {
        rawUserAnswer: string;
        processedAnswer: any;
        aiResponse: string;
        embedding: number[];
        timestamp: Date;
      };
    };
    videoUrl?: string;
    cloudflareId?: string;
    price?: number;
  }

export type ExtendedEpisodeInfo = Partial<EpisodeInfo> & {
  episodeName?: string;
  _id?: ObjectId;
};

export interface EpisodeNameResponse {
  message: string;
  episodeName: string;
  episodeId: string;
  error?: string;
}

export interface StepResponse {
  message: string;
  error?: string;
  currentStep: string;
  nextStep: string | null;
  episodeName?: string;
  episodeDescription?: string;
  videoLocation?: string;
  recordingNeighborhood?: string;
  recordingCity?: string;
  recordingState?: string;
  recordingCountry?: string;
  recordingLandmark?: string;
  episodeParticipants?: EpisodeParticipants;
  episodeOverview?: string;
  episodeData?: any;
  presignedUrl?: string;
  roundingAmount?: number;
  [key: string]: any;
}