import React, { useState, useEffect, useRef, useCallback } from 'react';
import Image from 'next/image';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import { Play, Loader2 } from 'lucide-react';
import VideoPlayerManager from '@/app/(dashboard)/(routes)/conversation/components/videoplayer/VideoPlayerManager';
import { useRouter } from 'next/navigation';

interface PodcastTopic {
  id: string;
  title: string;
  creator: string;
  category: string;
  videoUrl: string;
  duration: number;
  summary: string;
}

const mobileStyles = `
  @media (max-width: 640px) {
    .mobile-section {
      padding: 2rem 1rem !important;
    }

    .mobile-heading {
      font-size: 2rem !important;
      line-clamp: 2;
    }

    .mobile-subheading {
      font-size: 0.75rem !important;
    }

    .mobile-text {
      font-size: 1rem !important;
      line-height: 1.5 !important;
    }

    .mobile-button {
      width: 100% !important;
      padding: 0.75rem 1rem !important;
    }

    .mobile-grid {
      grid-template-columns: 1fr !important;
      height: auto !important;
      gap: 1rem !important;
    }

    .mobile-featured-card {
      height: 400px !important;
    }

    .mobile-card {
      height: 140px !important;
      padding: 1rem !important;
    }

    .mobile-card-title {
      font-size: 1.125rem !important;
      line-height: 1.4 !important;
    }

    .mobile-card-meta {
      font-size: 0.75rem !important;
    }

    .mobile-modal-content {
      margin: 1rem !important;
      padding: 1rem !important;
    }

    .mobile-modal-title {
      font-size: 1.5rem !important;
    }

    .mobile-modal-text {
      font-size: 0.875rem !important;
    }
  }
`;

const AmplifyYourVoice: React.FC = () => {
  const [podcastPreviews, setPodcastPreviews] = useState<PodcastTopic[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentVideo, setCurrentVideo] = useState<PodcastTopic | null>(null);
  const videoRefs = useRef<{ [key: string]: HTMLVideoElement }>({});
  const [soundEnabledVideos, setSoundEnabledVideos] = useState<Set<string>>(new Set());
  const [error, setError] = useState<string | null>(null);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const router = useRouter();

  useEffect(() => {
    const fetchPreviews = async () => {
      try {
        setIsLoading(true);
        const response = await fetch('/api/random-podcast-previews');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setPodcastPreviews(data);
      } catch (error) {
        console.error('Error fetching podcast previews:', error);
        setError('Failed to load podcast previews. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPreviews();
  }, []);

  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = mobileStyles;
    document.head.appendChild(styleSheet);
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  const handlePlayVideo = useCallback((topic: PodcastTopic) => {
    setSelectedId(topic.id);
    setCurrentVideo(topic);
  }, []);

  const toggleSound = useCallback((topicId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setSoundEnabledVideos(prev => {
      const newSet = new Set(prev);
      newSet.has(topicId) ? newSet.delete(topicId) : newSet.add(topicId);
      return newSet;
    });
  }, []);

  const handleMouseEnter = (topicId: string) => {
    const video = videoRefs.current[topicId];
    if (video) {
      video.currentTime = 0;
      video.muted = !soundEnabledVideos.has(topicId);
      video.play().catch(error => console.error('Error playing video:', error));
    }
  };

  const handleMouseLeave = (topicId: string) => {
    const video = videoRefs.current[topicId];
    if (video) {
      video.pause();
      video.currentTime = 0;
      video.muted = true;
    }
  };

  return (
    <LayoutGroup>
      <section className="bg-black text-white py-8 sm:py-16 px-4 sm:px-8 mobile-section">
        <div className="max-w-7xl mx-auto flex flex-col lg:flex-row gap-12">
          {/* Left column */}
          <div className="lg:w-1/2 space-y-6">
            <h3 className="text-xs font-medium tracking-[0.2em] text-pink-500/90 uppercase">What MELO does</h3>
            <h2 className="text-4xl lg:text-5xl font-[350] leading-tight tracking-[-0.02em] mobile-heading">Find Your Audience</h2>
            <p className="text-gray-300 text-lg leading-relaxed mobile-text">
              Imagine reaching an audience you can talk to and share intrests with in you industry or city. MELO connects you to those who find the most vlaue in your unique prespective. 
            </p>
            <button 
              className="bg-transparent text-white px-6 py-3 rounded-lg border border-white hover:bg-white hover:text-black transition duration-300 shadow-[0_0_15px_rgba(0,100,255,0.5)] hover:shadow-[0_0_20px_rgba(0,100,255,0.8)] mobile-button"
              onClick={() => router.push('/topics')}
            >
              Explore MELO
            </button>
          </div>

          {/* Right column */}
          <div className="lg:w-1/2">
            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <Loader2 className="animate-spin text-zinc-500" size={48} />
              </div>
            ) : (
              <div className="grid grid-cols-12 gap-4 h-[600px] mobile-grid">
                {/* Featured Card - First podcast preview */}
                <motion.div 
                  layoutId={`card-${podcastPreviews[0]?.id}`}
                  className="col-span-7 relative rounded-3xl overflow-hidden bg-zinc-900/50 backdrop-blur-sm cursor-pointer mobile-featured-card"
                  whileHover={{ scale: 0.99 }}
                  transition={{ duration: 0.2, layout: { duration: 0.6 } }}
                  onClick={() => handlePlayVideo(podcastPreviews[0])}
                >
                  <div className="absolute inset-0 bg-gradient-to-br from-purple-900/20 to-black" />
                  <div className="p-8 h-full flex flex-col justify-between relative">
                    <div>
                      <span className="text-pink-500 text-xs font-medium px-3 py-1 rounded-full bg-pink-500/10">
                        FEATURED
                      </span>
                      <h3 className="text-3xl font-bold mt-4 mb-2 line-clamp-2">{podcastPreviews[0]?.title}</h3>
                      <p className="text-zinc-400 text-base max-w-md line-clamp-3">
                        {podcastPreviews[0]?.summary}
                      </p>
                    </div>
                    <div className="mt-4">
                      <p className="font-medium text-lg">{podcastPreviews[0]?.creator}</p>
                      <p className="text-sm text-zinc-500">{podcastPreviews[0]?.category}</p>
                    </div>
                  </div>
                </motion.div>

                {/* Right Stack - Remaining previews */}
                <div className="col-span-5 space-y-4 mobile-card">
                  {podcastPreviews.slice(1, 4).map((podcast) => (
                    <motion.div
                      key={podcast.id}
                      layoutId={`card-${podcast.id}`}
                      className="relative rounded-2xl overflow-hidden bg-zinc-900/50 backdrop-blur-sm p-5 h-[160px] group cursor-pointer"
                      whileHover={{ scale: 0.98 }}
                      transition={{ duration: 0.2, layout: { duration: 0.6 } }}
                      onClick={() => handlePlayVideo(podcast)}
                    >
                      <div className="absolute inset-0 bg-gradient-to-r from-zinc-900 to-black opacity-50" />
                      <div className="relative h-full flex flex-col justify-between">
                        <div>
                          <span className="text-xs font-medium text-zinc-400 bg-zinc-800/80 px-2 py-1 rounded-full">
                            TRENDING
                          </span>
                          <h4 className="text-lg font-bold mt-2 mb-1 line-clamp-2 group-hover:text-pink-400 transition-colors">
                            {podcast.title}
                          </h4>
                        </div>
                        <div className="flex items-center justify-between text-xs">
                          <span className="text-zinc-400">{podcast.creator}</span>
                          <span className="bg-zinc-800 px-2 py-1 rounded-full">
                            {podcast.category}
                          </span>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Loading State */}
        {isLoading && (
          <div className="flex justify-center items-center h-64">
            <Loader2 className="animate-spin text-zinc-500" size={48} />
          </div>
        )}

        {/* Error State */}
        {error && (
          <div className="text-red-500 bg-red-100/10 p-4 rounded-lg">
            {error}
          </div>
        )}

        <AnimatePresence>
          {currentVideo && (
            <motion.div 
              layoutId={`card-${selectedId}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-90 flex items-start justify-center z-50 overflow-y-auto"
              onClick={() => {
                setSelectedId(null);
                setCurrentVideo(null);
              }}
            >
              <motion.div 
                className="w-full max-w-4xl relative my-8"
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.9 }}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="rounded-lg overflow-hidden shadow-2xl border border-zinc-700 relative">
                  <div className="absolute inset-0 animate-lightning bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-pink-500/20 rounded-lg" style={{ filter: 'blur(20px)' }}></div>
                  <div className="relative pt-[56.25%]">
                    <div className="absolute inset-0">
                      <VideoPlayerManager 
                        url={currentVideo.videoUrl}
                        podcastName={currentVideo.creator}
                        episodeName={currentVideo.title}
                        start={0}
                        end={currentVideo.duration}
                        isFullEpisode={true}
                        onToggleFullEpisode={() => {}}
                        clipDuration={currentVideo.duration}
                      />
                    </div>
                  </div>
                </div>
                
                <motion.div 
                  className="mt-4 bg-black rounded-lg p-6 text-white shadow-2xl border border-zinc-800"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                >
                  <h3 className="text-2xl font-bold mb-3 border-b border-zinc-800 pb-3 tracking-wider">{currentVideo.title}</h3>
                  <p className="text-zinc-400 mb-4 italic font-light">{currentVideo.creator}</p>
                  <div className="flex justify-between items-center mb-5">
                    <span className="text-sm bg-zinc-900 px-3 py-1 rounded-full backdrop-blur-sm">{currentVideo.category}</span>
                    <span className="text-sm text-zinc-500 font-mono">{formatDuration(currentVideo.duration)}</span>
                  </div>
                  <p className="text-sm text-zinc-300 mb-5 bg-zinc-900 p-4 rounded-lg backdrop-filter backdrop-blur-lg">{currentVideo.summary}</p>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </section>
    </LayoutGroup>
  );
};

const formatDuration = memoize((seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
});

function memoize<T extends (...args: any[]) => any>(fn: T) {
  const cache = new Map();
  return (...args: Parameters<T>): ReturnType<T> => {
    const key = JSON.stringify(args);
    if (cache.has(key)) return cache.get(key);
    const result = fn(...args);
    cache.set(key, result);
    return result;
  };
}

export default AmplifyYourVoice;
