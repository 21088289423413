import React, { useState } from 'react';
import { VideoUploadSteps } from '@/app/api/video/types';
import ProgressIndicator from '../../../components/ProgressIndicator';
import Link from 'next/link';

const PodcastUploadProgress: React.FC = () => {
  // Use the first step from VideoUploadSteps enum
  const [currentStep, setCurrentStep] = useState<VideoUploadSteps>(Object.values(VideoUploadSteps)[0]);
  const [completedSteps, setCompletedSteps] = useState<VideoUploadSteps[]>([]);

  const handleStepChange = (step: VideoUploadSteps) => {
    setCurrentStep(step);
    if (!completedSteps.includes(step)) {
      setCompletedSteps([...completedSteps, step]);
    }
  };

  return (
    <section className="text-white py-16 px-8">
      <div className="max-w-7xl mx-auto flex flex-col gap-12">
        {/* Enhanced title section with more padding */}
        <div className="text-center space-y-4 py-4">
          <h2 className="text-6xl font-light">
            Upload Your Videos
          </h2>
          <p className="text-zinc-400 text-xl">Just a few clicks to get your story out there</p>
        </div>

        {/* Progress indicator */}
        <ProgressIndicator
          currentStep={currentStep}
          onStepChange={handleStepChange}
          completedSteps={completedSteps}
        />

        {/* Content section */}
        <div className="flex flex-col lg:flex-row gap-12">
          {/* Left column */}
          <div className="lg:w-1/2 flex flex-col items-center justify-center space-y-8">
            <div className="relative">
              <Link href="/sign-up" passHref>
                <button className="px-8 py-4 text-lg font-light text-white bg-transparent border-2 border-white rounded-lg hover:bg-white hover:text-black transition-all duration-300 shadow-[0_0_25px_rgba(0,100,255,0.7)] hover:shadow-[0_0_35px_rgba(0,150,255,0.9)]">
                  Upload your first video 
                </button>
              </Link>
            </div>
            <div className="text-center text-zinc-500 text-sm">
              Start your journey today
            </div>
          </div>

          {/* Right column */}
          <div className="lg:w-1/2 space-y-6">
            <h3 className="text-xs font-medium tracking-[0.2em] text-pink-500/90 uppercase">What MELO Provides</h3>
            <h3 className="text-2xl font-semibold">The fastest video upload engine</h3>
            <p className="text-gray-300 text-lg leading-relaxed">
              Upload your video , and our context engine will analyze it and send it to the right audience for you. Build genuine connections with a network of people who share similar interests, hobbies, professions, or locations. You don't have to be anyone here, just yourself. Our algorithm will do the rest. Use MELO in your life journey, from building your startup to having couch conversations with your loved ones.
            </p>
            <ul className="list-disc list-inside text-zinc-400 space-y-2">
              <li>Personalized content recommendations</li>
              <li>Context-based search engine</li>
              <li>Niche audience targeting</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PodcastUploadProgress;
