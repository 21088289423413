'use client';

import React, { useState, useEffect } from "react";
import { Quicksand } from 'next/font/google';
import { useSession, SessionProvider } from "next-auth/react";
import LandingHero from "@/components/landing-hero";
import NavigationBar from "@/components/NavigationBar";
import Image from 'next/image';
import '@/app/globals.css';
import ConversationPreview from './components/ConversationPreview';
import PodcastTopicsGrid from './components/PodcastTopicsGrid';
import AmplifyYourVoice from './components/AmplifyYourVoice';
import PodcastUploadProgress from './components/PodcastUploadProgress';

import PodcastHero from './components/PodcastHero';
import Footer from './components/Footer';
import TopicsFeatureCard from './components/TopicsFeatureCard';
import ProfileSetupCard from './components/ProfileSetupCard';
import { useRouter } from 'next/navigation';
import CreatorCTA from './components/CreatorCTA';

const quicksand = Quicksand({ subsets: ['latin'], weight: ['300'] });

const LandingPageContent: React.FC = () => {
  const { data: session, status } = useSession();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [isProfileSetupVisible, setIsProfileSetupVisible] = useState(true);

  const isDevComplete = true; // Set this to true when development is complete

  useEffect(() => {
    setMounted(true);
    // Simulate a short loading time
    setTimeout(() => setIsLoading(false), 1000);

    // Redirect to dashboard if user is authenticated
    if (status === "authenticated") {
      router.push('/dashboard');
    }
  }, [status, router]);

  if (!mounted) {
    return null; // or a simple loading placeholder
  }

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 bg-black z-50 flex items-center justify-center">
          <Image
            src="/logo.png"
            alt="Logo"
            style={{ objectFit: 'cover' }}
            width={350}
            height={350}
            className="animate-pulse"
          />
        </div>
      )}
      <div className={`${quicksand.className} min-h-screen overflow-x-hidden bg-black ${isLoading ? 'invisible' : 'fade-in'}`}>
        <div className="relative min-h-screen flex flex-col">
          <NavigationBar isDevComplete={isDevComplete} />
          <main className="flex-grow flex flex-col items-center justify-start px-4 sm:px-4 py-8 sm:py-14">
            <div className="w-full max-w-6xl space-y-12 sm:space-y-22">
              <LandingHero isDevComplete={isDevComplete} />
              <div className="h-4"></div>
              <ConversationPreview isSignedIn={status === "authenticated"} />
              
              <section className="flex flex-col items-center mt-9">
                <div className="w-full">
                  <PodcastTopicsGrid />
                </div>
              </section>

              <div className="h-24"></div>

              <AmplifyYourVoice />

              <div className="h-24 sm:h-32 md:h-40"></div>

              <ProfileSetupCard isVisible={isProfileSetupVisible} />

              <div className="h-24"></div>

              <TopicsFeatureCard />

              <section className="mt-28">
                <PodcastUploadProgress />
              </section>

              <CreatorCTA />

              <div className="scale-90 transform origin-center mt-14">
                <PodcastHero />
              </div>
              <div className="h-24"></div>

              <Footer />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

const LandingPage: React.FC = () => {
  return (
    <SessionProvider>
      <LandingPageContent />
    </SessionProvider>
  );
};

export default LandingPage;
