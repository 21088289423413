// components/ProgressIndicator.tsx is for the progress indicator on the video upload page 

import React from 'react';
import { VideoUploadSteps } from '@/app/api/video/types';
import { useMediaQuery } from 'react-responsive';

interface ProgressIndicatorProps {
  currentStep: VideoUploadSteps;
  onStepChange: (step: VideoUploadSteps) => void;
  completedSteps: VideoUploadSteps[];
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({ currentStep, onStepChange, completedSteps }) => {
  const steps = Object.values(VideoUploadSteps);
  const currentStepIndex = steps.indexOf(currentStep);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });

  return (
    <div className="w-full py-4 md:py-6">
      <div className={`flex ${isMobile ? 'flex-col' : 'justify-between'} mb-3`}>
        {steps.map((step, index) => (
          <button
            key={step}
            onClick={() => onStepChange(step)}
            className={`text-sm font-medium px-3 py-2 md:px-4 md:py-2 transition-all duration-300 border ${
              isMobile ? 'mb-2 w-full' : ''
            } ${
              index === currentStepIndex
                ? 'text-zinc-500 bg-transparent border-zinc-500'
                : index < currentStepIndex || completedSteps.includes(step)
                ? 'text-zinc-500 hover:text-zinc-700 hover:bg-transparent border-zinc-700'
                : 'text-zinc-500 cursor-not-allowed border-zinc-800 bg-transparent'
            }`}
            disabled={index > currentStepIndex && !completedSteps.includes(step)}
          >
            {step.replace(/([A-Z])/g, ' $1').trim().toLowerCase()}
          </button>
        ))}
      </div>
      <div className="w-full bg-zinc-800 h-1.5 relative">
        <div
          className="h-full transition-all duration-500 ease-out"
          style={{
            width: `${((currentStepIndex + 1) / steps.length) * 100}%`,
            background: 'linear-gradient(to right, #3a3a3a, #4A5D8A)',
          }}
        >
          <div 
            className="absolute top-0 left-0 right-0 h-full opacity-40"
            style={{
              background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.15), transparent)',
              animation: 'shimmer 2s infinite',
            }}
          ></div>
        </div>
      </div>
      {(isMobile || isTablet) && (
        <style jsx>{`
          button {
            touch-action: manipulation;
          }
        `}</style>
      )}
    </div>
  );
};

export default ProgressIndicator;