"use client";

import { useAuth } from "@clerk/nextjs";
import { motion } from "framer-motion";

const HERO_TEXT = "The New Video Sharing Platform";

interface LandingHeroProps {
  isDevComplete: boolean;
}

const LandingHero: React.FC<LandingHeroProps> = ({ isDevComplete }) => {
  const { isSignedIn } = useAuth();

  return (
    <div className="text-white font-thin py-6 sm:py-12 text-center max-w-8xl mx-auto px-6 sm:px-12 flex flex-col justify-center">
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extralight tracking-tight leading-[1.1] pb-4 font-quicksand bg-gradient-to-r from-white via-zinc-100 to-white bg-clip-text text-transparent drop-shadow-[0_0_15px_rgba(255,255,255,0.15)] mt-16 sm:mt-0"
      >
        {HERO_TEXT}
      </motion.h1>
    </div>
  );
};

export default LandingHero;
