import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Play, Loader2 } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { formatDuration } from '@/lib/utils';
import VideoPlayerManager from '@/app/(dashboard)/(routes)/conversation/components/videoplayer/VideoPlayerManager';
import Image from 'next/image';
import { cn } from "@/lib/utils";
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { Button } from '@/components/ui/button';

interface PodcastTopic {
  id: string;
  title: string;
  creator: string;
  category: string;
  videoUrl: string;
  duration: number;
  summary: string;
}

// Add proper types
interface VideoRef {
  [key: string]: HTMLVideoElement;
}

// Extract modal component
const PodcastModal: React.FC<{
  video: PodcastTopic;
  onClose: () => void;
}> = ({ video, onClose }) => {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-90 flex items-start justify-center z-50 overflow-y-auto"
      onClick={onClose}
    >
      <motion.div 
        className="w-full max-w-4xl relative my-8"
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.9 }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="rounded-lg overflow-hidden shadow-2xl border border-zinc-700 relative">
          <div className="absolute inset-0 animate-lightning bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-pink-500/20 rounded-lg" style={{ filter: 'blur(20px)' }}></div>
          <div className="relative pt-[56.25%]">
            <div className="absolute inset-0">
              <VideoPlayerManager 
                url={video.videoUrl}
                podcastName={video.creator}
                episodeName={video.title}
                start={0}
                end={video.duration}
                isFullEpisode={true}
                onToggleFullEpisode={() => {}}
                clipDuration={video.duration}
              />
            </div>
          </div>
        </div>
        
        <motion.div 
          className="mt-4 bg-black rounded-lg p-6 text-white shadow-2xl border border-zinc-800"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <h3 className="text-2xl font-bold mb-3 border-b border-zinc-800 pb-3 tracking-wider">{video.title}</h3>
          <p className="text-zinc-400 mb-4 italic font-light">{video.creator}</p>
          <div className="flex justify-between items-center mb-5">
            <span className="text-sm text-zinc-500 font-mono">{formatDuration(video.duration)}</span>
          </div>
          <p className="text-sm text-zinc-300 mb-5 bg-zinc-900 p-4 rounded-lg backdrop-filter backdrop-blur-lg">{video.summary}</p>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

// Add new type for video states
interface VideoState {
  isLoading: boolean;
  error: boolean;
  isInitialized: boolean;
}

// Add interface for cache entry at the top with other interfaces
interface PreviewCacheEntry {
  dataUrl: string;
}

// Add this new interface for touch tracking
interface TouchState {
  startX: number;
  startY: number;
  startTime: number;
}

const PodcastTopicsGrid: React.FC = () => {
  const [topics, setTopics] = useState<PodcastTopic[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentVideo, setCurrentVideo] = useState<PodcastTopic | null>(null);
  const videoRefs = useRef<VideoRef>({});
  const [soundEnabledVideos, setSoundEnabledVideos] = useState<Set<string>>(new Set());
  const { data: session } = useSession();
  const router = useRouter();
  const [touchStartTime, setTouchStartTime] = useState<number>(0);
  const [videoStates, setVideoStates] = useState<Record<string, VideoState>>({});
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [hoveredVideo, setHoveredVideo] = useState<string | null>(null);
  const previewCache = useRef<Record<string, PreviewCacheEntry>>({});
  const [touchState, setTouchState] = useState<TouchState | null>(null);
  const touchThreshold = 10; // pixels for touch movement threshold

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        // Add a random query parameter to force a new request each time
        const response = await fetch(`/api/random-podcast-topics?t=${Date.now()}`);
        if (!response.ok) {
          throw new Error('Failed to fetch topics');
        }
        const data = await response.json();
        setTopics(data);
      } catch (error) {
        console.error('Error fetching podcast topics:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTopics();
  }, []);

  // Optimize handlers with useCallback
  const handlePlayVideo = useCallback((topic: PodcastTopic) => {
    setCurrentVideo(topic);
  }, []);

  const toggleSound = useCallback((topicId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setSoundEnabledVideos(prev => {
      const newSet = new Set(prev);
      if (newSet.has(topicId)) {
        newSet.delete(topicId);
      } else {
        newSet.add(topicId);
      }
      return newSet;
    });
  }, []);

  // Move these callbacks before the useEffect that uses them
  const initializeVideo = useCallback((topicId: string) => {
    const video = videoRefs.current[topicId];
    if (!video || videoStates[topicId]?.isInitialized) return;

    setVideoStates(prev => ({
      ...prev,
      [topicId]: { isLoading: true, error: false, isInitialized: true }
    }));

    video.crossOrigin = "anonymous";
    video.load();
    
    video.addEventListener('loadeddata', () => {
      if (!previewCache.current[topicId]) {
        generateVideoPreview(video, topicId)
          .then(() => {
            setVideoStates(prev => ({
              ...prev,
              [topicId]: { 
                ...prev[topicId], 
                isLoading: false,
              }
            }));
          })
          .catch(error => {
            console.error('Failed to generate preview:', error);
            setVideoStates(prev => ({
              ...prev,
              [topicId]: { 
                ...prev[topicId], 
                isLoading: false,
                error: true,
              }
            }));
          });
      } else {
        setVideoStates(prev => ({
          ...prev,
          [topicId]: { 
            ...prev[topicId], 
            isLoading: false,
          }
        }));
      }
    });
  }, [videoStates]);

  const cleanupVideo = useCallback((topicId: string) => {
    const video = videoRefs.current[topicId];
    if (!video) return;

    video.pause();
    video.removeAttribute('src');
    video.load();

    setVideoStates(prev => ({
      ...prev,
      [topicId]: { 
        isLoading: false, 
        error: false, 
        isInitialized: false, 
      }
    }));
  }, []);

  // Now use them in useEffect
  useEffect(() => {
    if (!observerRef.current) {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const topicId = entry.target.getAttribute('data-topic-id');
            if (topicId && entry.isIntersecting) {
              initializeVideo(topicId);
            } else if (topicId && !entry.isIntersecting) {
              cleanupVideo(topicId);
            }
          });
        },
        { rootMargin: '50px' }
      );
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
        observerRef.current = null;
      }
    };
  }, [initializeVideo, cleanupVideo]);

  const generateVideoPreview = async (video: HTMLVideoElement, topicId: string): Promise<string> => {
    // Check cache first
    if (previewCache.current[topicId]) {
      return previewCache.current[topicId].dataUrl;
    }

    return new Promise((resolve, reject) => {
      try {
        const previewVideo = document.createElement('video');
        previewVideo.crossOrigin = "anonymous";
        previewVideo.muted = true;
        previewVideo.playsInline = true;

        previewVideo.addEventListener('loadedmetadata', () => {
          // Always use 20% of the video duration for the preview
          const timestamp = previewVideo.duration * 0.6;
          previewVideo.currentTime = timestamp;
        });

        previewVideo.addEventListener('seeked', () => {
          try {
            const canvas = document.createElement('canvas');
            canvas.width = previewVideo.videoWidth;
            canvas.height = previewVideo.videoHeight;
            const ctx = canvas.getContext('2d');
            
            if (!ctx) {
              reject(new Error('Failed to get canvas context'));
              return;
            }

            ctx.drawImage(previewVideo, 0, 0);
            const dataUrl = canvas.toDataURL('image/jpeg', 0.7);
            
            // Simpler cache structure
            previewCache.current[topicId] = { dataUrl };
            
            previewVideo.remove();
            resolve(dataUrl);
          } catch (error) {
            reject(error);
          }
        });

        previewVideo.addEventListener('error', (e) => {
          reject(new Error(`Video preview failed to load: ${e.message}`));
        });

        // Force immediate load at 0.1s
        previewVideo.src = `${video.currentSrc}#t=0.1`;
        previewVideo.load();
      } catch (error) {
        reject(error);
      }
    });
  };

  // Update the video element render
  const videoElement = (topic: PodcastTopic) => (
    <div 
      data-topic-id={topic.id}
      ref={el => {
        if (el && observerRef.current) {
          observerRef.current.observe(el);
        }
      }}
      className="relative w-full h-full"
    >
      {/* Preview image - now always visible by default */}
      {previewCache.current[topic.id] && (
        <div 
          className={cn(
            "absolute inset-0 bg-cover bg-center transition-opacity duration-300",
            // Only hide preview on hover for non-touch devices
            "md:group-hover:opacity-0 pointer-events-none"
          )}
          style={{ 
            backgroundImage: `url(${previewCache.current[topic.id].dataUrl})`,
            zIndex: 1
          }}
        />
      )}

      {/* Base video element - hidden on mobile unless playing */}
      <video
        ref={el => { 
          if (el) videoRefs.current[topic.id] = el;
        }}
        className={cn(
          "w-full h-full object-cover",
          // Hide video on mobile unless it's being hovered/played
          hoveredVideo === topic.id ? "opacity-100" : "opacity-0 md:opacity-100"
        )}
        muted={!soundEnabledVideos.has(topic.id)}
        playsInline
        preload="metadata"
        crossOrigin="anonymous"
        webkit-playsinline="true"
      >
        <source src={topic.videoUrl} type="video/mp4" />
      </video>

      {/* Loading spinner */}
      {videoStates[topic.id]?.isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black/50 z-20">
          <Loader2 className="animate-spin text-white" size={24} />
        </div>
      )}

      {/* Overlay gradient and controls - always visible */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent z-30">
        <div className="absolute inset-0 flex flex-col justify-between p-4">
          {/* Top controls */}
          <div className="flex justify-between items-start">
            <div className="bg-white/20 backdrop-blur-sm rounded-full p-2 transition-transform hover:scale-105">
              <Play className="text-white" size={24} />
            </div>
            <button
              onClick={(e) => toggleSound(topic.id, e)}
              className="bg-white/20 backdrop-blur-sm rounded-full p-2 transition-transform hover:scale-105"
            >
              <Image
                src={soundEnabledVideos.has(topic.id) ? '/Mute Off.png' : '/Mute On.png'}
                alt={soundEnabledVideos.has(topic.id) ? 'Sound On' : 'Sound Off'}
                width={24}
                height={24}
              />
            </button>
          </div>

          {/* Bottom info */}
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <span className="text-sm text-zinc-300 font-mono">
                {formatDuration(topic.duration)}
              </span>
            </div>
            <div>
              <h4 className="text-xl font-semibold text-white truncate">
                {topic.title}
              </h4>
              <p className="text-sm text-zinc-300 truncate">
                {topic.creator}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // Cleanup videos on unmount
  useEffect(() => {
    return () => {
      // Clear video refs but keep the preview cache
      Object.keys(videoRefs.current).forEach(cleanupVideo);
      videoRefs.current = {};
    };
  }, [cleanupVideo]);

  const handleMouseEnter = useCallback((topicId: string) => {
    setHoveredVideo(topicId);
    const video = videoRefs.current[topicId];
    if (video) {
      video.currentTime = 0;
      video.muted = !soundEnabledVideos.has(topicId);
      video.play().catch(error => console.error('Error playing video:', error));
    }
  }, [soundEnabledVideos]);

  const handleMouseLeave = useCallback((topicId: string) => {
    setHoveredVideo(null);
    const video = videoRefs.current[topicId];
    if (video) {
      video.pause();
      video.currentTime = 0;
      video.muted = true;
    }
  }, []);

  const handleExploreTopics = () => {
    router.push('/topics');
  };

  // Update touch handlers
  const handleTouchStart = (topicId: string, e: React.TouchEvent) => {
    const touch = e.touches[0];
    setTouchState({
      startX: touch.clientX,
      startY: touch.clientY,
      startTime: Date.now()
    });
    // Don't start video immediately on touch
  };

  const handleTouchEnd = (topicId: string, e: React.TouchEvent) => {
    if (!touchState) return;

    const touch = e.changedTouches[0];
    const deltaX = Math.abs(touch.clientX - touchState.startX);
    const deltaY = Math.abs(touch.clientY - touchState.startY);
    const touchDuration = Date.now() - touchState.startTime;

    // If it's a tap (minimal movement and short duration)
    if (deltaX < touchThreshold && deltaY < touchThreshold && touchDuration < 200) {
      handlePlayVideo(topics.find(t => t.id === topicId)!);
    }
    
    setTouchState(null);
  };

  // Add touch move handler
  const handleTouchMove = (topicId: string, e: React.TouchEvent) => {
    if (!touchState) return;
    
    const touch = e.touches[0];
    const deltaX = Math.abs(touch.clientX - touchState.startX);
    const deltaY = Math.abs(touch.clientY - touchState.startY);

    // If user is scrolling, cancel the video preview
    if (deltaY > touchThreshold) {
      handleMouseLeave(topicId);
      setTouchState(null);
    }
    // If user is doing horizontal swipe, maybe preview the video
    else if (deltaX > touchThreshold) {
      handleMouseEnter(topicId);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="animate-spin text-zinc-500" size={48} />
      </div>
    );
  }

  return (
    <>
      {/* Update the header for mobile */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-8 py-4 sm:py-8 gap-4">
        <h2 className="text-3xl sm:text-4xl font-extrabold bg-gradient-to-r from-zinc-400 via-zinc-500 to-zinc-600 bg-clip-text text-transparent drop-shadow-sm">
          Trending videos on MELO
        </h2>
        {session && (
          <Button
            onClick={handleExploreTopics}
            className="w-full sm:w-auto bg-pink-500 hover:bg-pink-600 text-white"
          >
            Explore More Topics
          </Button>
        )}
      </div>

      {/* Update the grid for mobile */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-6">
        {topics.map((topic, index) => (
          <motion.div
            key={topic.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className={cn(
              "rounded-lg overflow-hidden cursor-pointer group relative",
              {
                "sm:col-span-2 aspect-[16/9]": index % 3 === 0,
                "aspect-[4/3]": index % 3 !== 0
              }
            )}
            onClick={() => handlePlayVideo(topic)}
            onMouseEnter={() => handleMouseEnter(topic.id)}
            onMouseLeave={() => handleMouseLeave(topic.id)}
            onTouchStart={(e) => handleTouchStart(topic.id, e)}
            onTouchMove={(e) => handleTouchMove(topic.id, e)}
            onTouchEnd={(e) => handleTouchEnd(topic.id, e)}
          >
            {videoElement(topic)}
          </motion.div>
        ))}
      </div>

      {/* Update modal for better mobile experience */}
      <AnimatePresence>
        {currentVideo && (
          <PodcastModal 
            video={currentVideo} 
            onClose={() => setCurrentVideo(null)} 
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default PodcastTopicsGrid;
