import React from 'react';
import { motion } from 'framer-motion';
import { useRouter } from 'next/navigation';
import Image from 'next/image';
import { JetBrains_Mono } from 'next/font/google';

const jetbrainsMono = JetBrains_Mono({
  subsets: ['latin'],
  weight: ['100', '400', '700'],
});

const CreatorCTA: React.FC = () => {
  const router = useRouter();

  const shimmer = {
    initial: { x: '-100%' },
    animate: { x: '100%' },
    transition: { repeat: Infinity, duration: 2.5, ease: [0.4, 0, 0.2, 1] }
  };

  return (
    <section className="relative py-12 sm:py-24 px-4 sm:px-8 overflow-hidden">
      {/* Smoother background gradient */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,rgba(93,0,255,0.08)_0%,transparent_70%)]" />
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_bottom,rgba(255,0,255,0.05)_0%,transparent_70%)]" />
        <div className="absolute inset-0 bg-gradient-to-b from-black/0 via-black/5 to-black/10" />
      </div>
      
      {/* Ultra subtle noise overlay */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[url('/noise.png')] opacity-[0.01] mix-blend-overlay animate-grain" />
      </div>

      {/* Main content */}
      <div className="max-w-3xl mx-auto relative">
        <motion.div 
          className="space-y-6 lg:space-y-8"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2, duration: 0.6 }}
        >
          <div className="space-y-4">
            <h2 className="text-4xl sm:text-5xl lg:text-6xl font-bold tracking-tight">
              <span className="block text-white opacity-95">Create.</span>
              <span className="block text-zinc-300">Share.</span>
              <span className="block bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 bg-clip-text text-transparent">
                Earn.
              </span>
            </h2>
            <p className="text-base lg:text-lg text-zinc-300 leading-relaxed max-w-xl">
              Use MELO to create engaging video podcasts and vlogs and get discovered by your perfect audience. 
              Upload your content and let our AI-powered platform connect you with viewers who share 
              your interests, boosting your reach and engagement.
            </p>
          </div>

          <motion.button
            onClick={() => router.push('/sign-up')}
            className="
              w-full sm:w-auto
              px-6 sm:px-8 py-3 sm:py-4
              relative 
              bg-transparent 
              text-white 
              border-2 
              border-white/80
              rounded-lg
              hover:bg-white/95
              hover:text-black 
              transition-all 
              duration-300 
              shadow-[0_0_25px_rgba(0,100,255,0.3)]
              hover:shadow-[0_0_50px_rgba(0,100,255,0.5)]
              text-lg
              font-medium
              tracking-wide
              overflow-hidden
              after:absolute after:inset-0 after:bg-white/0 after:hover:bg-white/10 after:transition-colors
              before:absolute before:inset-0 before:rounded-lg before:bg-blue-500/20 before:blur-xl before:opacity-0 before:hover:opacity-100 before:transition-opacity
            "
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.98 }}
          >
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent"
              {...shimmer}
            />
            <span className="relative z-10">Become a Producer</span>
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
};

export default CreatorCTA;
