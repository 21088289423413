import React from 'react';
import { Button } from '@/components/ui/button';

interface SuggestedQuestionsProps {
  onQuestionClick: (question: string) => void;
}

const SuggestedQuestions: React.FC<SuggestedQuestionsProps> = ({ onQuestionClick }) => {
  const suggestedQuestions = [
    "Where should I travel in Europe?",
    "How can I boost my confidence?",
    "What's a good way to make new friends?",
    "How can I build genuine connections?",
  ];

  return (
    <div className="mt-8 max-w-3xl px-4 sm:px-0 mx-[-8px] sm:mx-0">
      <p className="text-zinc-500 text-[10px] sm:text-[11px] tracking-[0.12em] sm:tracking-[0.15em] uppercase mb-3 sm:mb-4 ml-[10px] sm:ml-[2px] font-medium select-none">
        Try asking
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-3 sm:gap-y-4 gap-x-4 sm:gap-x-12">
        {suggestedQuestions.map((question, index) => (
          <Button
            key={index}
            variant="ghost"
            className="
              group relative text-left h-auto p-0
              text-[14px] sm:text-[15px] font-light tracking-wide
              text-zinc-400/90 bg-transparent
              hover:text-white hover:bg-transparent
              transition-all duration-300 ease-out
              cursor-pointer select-none
              before:absolute before:left-[-2px] before:top-1/2 
              before:w-0.5 before:h-0 before:bg-zinc-400
              before:opacity-0 before:transition-all before:ease-in-out
              hover:before:h-3 hover:before:opacity-20
              hover:before:-translate-y-1/2
              after:absolute after:bottom-[-1px] after:left-0
              after:w-0 after:h-[1px] after:bg-gradient-to-r
              after:from-zinc-600/40 after:via-zinc-500/40 after:to-zinc-600/20
              after:transition-all after:duration-500 after:ease-in-out
              hover:after:w-full
              active:scale-[0.995] active:after:opacity-60
              pr-6 sm:pr-8
              min-w-0 sm:min-w-[240px]
              w-full sm:w-auto
              touch-manipulation
              active:bg-zinc-900/10 sm:active:bg-transparent
              py-2 sm:py-0
              px-3 sm:px-0
              rounded-sm sm:rounded-none
              shadow-sm active:shadow-none sm:shadow-none
              min-h-[44px] sm:min-h-0
              border-b border-zinc-800/10 sm:border-0
              last:border-b-0
            "
            onClick={() => onQuestionClick(question)}
          >
            <span className="
              bg-gradient-to-r from-transparent via-transparent to-transparent
              group-hover:from-transparent group-hover:via-zinc-800/10 group-hover:to-transparent
              absolute inset-0 opacity-0 group-hover:opacity-100
              transition-all duration-500 ease-in-out
              rounded-[1px]
            "/>
            <span className="
              relative z-10 transition-all duration-300
              group-hover:translate-x-[2px] sm:group-hover:translate-x-[3px]
              group-hover:font-normal
              group-active:translate-x-[3px] sm:group-active:translate-x-[4px]
              block truncate
            ">
              {question}
            </span>
            <span className="
              absolute right-0 top-1/2 -translate-y-1/2
              text-zinc-600 opacity-0 group-hover:opacity-100
              transition-all duration-300 ease-out
              transform translate-x-1 sm:translate-x-2 group-hover:translate-x-0
              text-[10px] sm:text-xs tracking-wider
              group-hover:text-zinc-500
              group-active:translate-x-[-1px] sm:group-active:translate-x-[-2px]
              group-active:text-zinc-400
              ml-2
              hidden sm:inline
            ">
              →
            </span>
            <span className="
              absolute inset-0 bg-zinc-800/0
              group-hover:bg-zinc-800/[0.001]
              transition-colors duration-300
              pointer-events-none
            "/>
          </Button>
        ))}
      </div>
    </div>
  );
};

export default SuggestedQuestions;

