import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Form, FormControl, FormField, FormItem } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import VideoPlayer from '@/app/(dashboard)/(routes)/conversation/components/videoplayer/VideoPlayer';
import { toast } from 'react-hot-toast';
import { FaPlay, FaStop } from 'react-icons/fa';
import { useRouter } from 'next/navigation';
import { formatTime } from '@/lib/utils';
import { CgSpinner } from 'react-icons/cg';
import { SearchProgressStep } from '@/app/api/conversation/types';
import SuggestedQuestions from './SuggestedQuestions';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { cn } from "@/lib/utils";

const formSchema = z.object({
  prompt: z.string().min(1, {
    message: "ask melo something ;)"
  })
});

interface ConversationPreviewProps {
  isSignedIn: boolean;
}

const ConversationPreview: React.FC<ConversationPreviewProps> = ({ isSignedIn }) => {
  const [messages, setMessages] = useState<any[]>([]);
  const [videoInfo, setVideoInfo] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [generationCount, setGenerationCount] = useState(0);
  const [triesCount, setTriesCount] = useState(0);
  const [usageCount, setUsageCount] = useState(0);
  const [progress, setProgress] = useState<SearchProgressStep[]>([]);
  const [showLimitReachedPopup, setShowLimitReachedPopup] = useState(false);
  const router = useRouter();

  // Add loading state for buttons
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);
  const [isSignInLoading, setIsSignInLoading] = useState(false);

  const [abortController, setAbortController] = useState<AbortController | null>(null);

  useEffect(() => {
    const savedTriesCount = localStorage.getItem('previewTriesCount');
    if (savedTriesCount) {
      setTriesCount(parseInt(savedTriesCount, 10));
    }

    const savedUsageCount = localStorage.getItem('previewUsageCount');
    if (savedUsageCount) {
      setUsageCount(parseInt(savedUsageCount, 10));
    }
  }, []);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      prompt: ""
    }
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if (isLoading) {
      // Cancel the ongoing request
      abortController?.abort();
      setIsLoading(false);
      setProgress([]);
      return;
    }

    if (!values.prompt.trim()) {
      toast.error("Please enter a question");
      return;
    }
    
    const controller = new AbortController();
    setAbortController(controller);
    setIsLoading(true);
    setVideoInfo(null);
    setProgress([]);

    try {
      const endpoint = isSignedIn ? '/api/conversation' : '/api/conversation-preview';
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          messages: [...messages, { role: 'user', content: values.prompt }],
          usageCount: usageCount
        }),
        signal: controller.signal, // Add abort signal
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 403 && errorData.type === 'error') {
          setShowLimitReachedPopup(true);
          throw new Error(errorData.message);
        }
        throw new Error("Failed to get a response");
      }

      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error("Failed to get response reader");
      }

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const decodedValue = new TextDecoder().decode(value);
        const events = decodedValue.split('\n\n').filter(Boolean);

        for (const event of events) {
          if (event.startsWith('data: ')) {
            const data = JSON.parse(event.slice(6));
            
            if (data.type === 'progress') {
              setProgress(data.data);
            } else if (data.type === 'result') {
              setMessages(prevMessages => [...prevMessages, { role: 'user', content: values.prompt }, data.data.message]);
              if (data.data.videoInfo) {
                setVideoInfo(data.data.videoInfo);
              }
              if (!isSignedIn) {
                setUsageCount(prevCount => {
                  const newCount = prevCount + 1;
                  localStorage.setItem('previewUsageCount', newCount.toString());
                  return newCount;
                });
              } else if (data.data.updatedGenerationCount !== undefined) {
                setGenerationCount(data.data.updatedGenerationCount);
              }
            } else if (data.type === 'error') {
              toast.error(data.message);
            }
          }
        }
      }

    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        toast.success('Request cancelled');
      } else {
        console.error('Error:', error);
        toast.error(error instanceof Error ? error.message : "An error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
      setProgress([]);
      setAbortController(null);
      form.reset();
    }
  };

  const LoadingSpinner: React.FC = () => (
    <div className="flex justify-center items-center mt-4">
      <CgSpinner className="animate-spin text-blue-500 text-4xl" />
    </div>
  );

  const ProgressIndicator: React.FC<{ progress: SearchProgressStep[] }> = ({ progress }) => (
    <div className="mt-4 space-y-2">
      {progress.map((step, index) => (
        <div key={index} className="flex items-center">
          <div className={`w-4 h-4 rounded-full mr-2 ${step.completed ? 'bg-green-500' : step.loading ? 'bg-blue-500 animate-pulse' : 'bg-gray-300'}`}></div>
          <span className={`${step.completed ? 'text-green-500' : step.loading ? 'text-blue-500' : 'text-gray-500'}`}>
            {step.step}
          </span>
        </div>
      ))}
    </div>
  );

  const handleQuestionClick = (question: string) => {
    form.setValue('prompt', question);
    form.handleSubmit(onSubmit)();
  };

  // Enhanced button handlers with loading states
  const handleSignUp = async () => {
    setIsSignUpLoading(true);
    try {
      await router.push('/sign-up');
    } finally {
      setIsSignUpLoading(false);
    }
  };

  const handleLogIn = async () => {
    setIsSignInLoading(true);
    try {
      await router.push('/sign-in');
    } finally {
      setIsSignInLoading(false);
    }
  };

  const handleButtonClick = () => {
    if (isSignedIn) {
      router.push('/topics');
    } else {
      setShowLimitReachedPopup(true);
    }
  };

  return (
    <div className="w-full max-w-3xl mx-auto space-y-6">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="relative max-w-4xl mx-auto">
          <FormField
            name="prompt"
            render={({ field }) => (
              <FormItem className="relative">
                <FormControl>
                  <div className="relative group perspective-1000">
                    {/* Cyberpunk Glow Effects */}
                    <div className="
                      absolute -inset-[2px]
                      bg-gradient-to-r from-blue-500 via-indigo-500 to-blue-500
                      rounded-full opacity-0
                      group-hover:opacity-100
                      blur-2xl transition-all duration-500
                      animate-pulse-slow
                    "/>
                    <div className="
                      absolute -inset-[1px]
                      bg-gradient-to-r from-transparent via-blue-500/20 to-transparent
                      rounded-full opacity-50
                      group-hover:opacity-0
                      blur-md transition-all duration-500
                    "/>

                    {/* Main Input Container */}
                    <div className="relative bg-black/80 rounded-full">
                      <Input
                        disabled={isLoading}
                        placeholder={isLoading ? "Processing your question..." : "ask melo something..."}
                        {...field}
                        className="
                          w-full bg-transparent
                          text-[16px] tracking-wider
                          text-blue-400 placeholder:text-zinc-600
                          py-8 pl-6 pr-16
                          border-0 rounded-full
                          ring-0 focus:ring-0
                          focus:outline-none
                          transition-all duration-300
                          group-hover:placeholder:text-zinc-500
                        "
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            form.handleSubmit(onSubmit)();
                          }
                        }}
                      />

                      {/* Play Button with Cyberpunk Style */}
                      <Button 
                        type="submit" 
                        disabled={false}
                        className="
                          absolute right-4 top-1/2 -translate-y-1/2
                          bg-transparent hover:bg-transparent
                          text-blue-500/50 hover:text-blue-400
                          p-4 rounded-full
                          transition-all duration-300
                          focus:outline-none
                          hover:scale-110
                        "
                      >
                        {isLoading ? (
                          <FaStop className="w-4 h-4 text-red-500/80 hover:text-red-500 transition-colors" />
                        ) : (
                          <FaPlay className="w-4 h-4" />
                        )}
                      </Button>
                    </div>
                  </div>
                </FormControl>
              </FormItem>
            )}
          />
        </form>
      </Form>

      <SuggestedQuestions onQuestionClick={handleQuestionClick} />

      {isLoading && <LoadingSpinner />}
      {progress.length > 0 && <ProgressIndicator progress={progress} />}

      {messages.length > 0 && (
        <div className="space-y-4">
          {messages.map((message, index) => (
            <div key={index} className={`p-4 rounded-lg backdrop-blur-md ${
              message.role === 'user' 
                ? 'bg-transparent border border-blue-500/30 text-white' 
                : 'bg-transparent border border-zinc-700/50 text-zinc-100'
            }`}>
              {message.role === 'user' ? (
                message.content
              ) : (
                <pre className="font-mono text-blue-500 text-xs overflow-x-auto whitespace-pre-wrap break-words shadow-[0_0_5px_rgba(59,130,246,0.5)]">
                  {`Podcast: ${videoInfo?.podcastName || 'N/A'}
Episode: ${videoInfo?.episodeName || 'N/A'}
Timestamp: ${videoInfo?.start ? formatTime(videoInfo.start) : 'N/A'} - ${videoInfo?.end ? formatTime(videoInfo.end) : 'N/A'}`}
                </pre>
              )}
            </div>
          ))}
        </div>
      )}

      {videoInfo && (
        <div className="mt-6">
          <VideoPlayer
            url={videoInfo.url}
            start={videoInfo.start}
            end={videoInfo.end}
            podcastName={videoInfo.podcastName}
            episodeName={videoInfo.episodeName}
            clipDuration={videoInfo.end - videoInfo.start}
            onToggleFullEpisode={() => {}}
          />
        </div>
      )}

      {generationCount > 0 && (
        <div className="text-center text-zinc-300">
          <p>Generation count: {generationCount}</p>
        </div>
      )}

      {usageCount > 0 && (
        <div className="text-center text-sm text-zinc-400 backdrop-blur-sm bg-transparent border border-zinc-700/30 py-2 rounded-full">
          {usageCount === 1 ? "1 free use" : `${usageCount} free uses`} used. {2 - usageCount} {2 - usageCount === 1 ? "use" : "uses"} remaining.
          <Button
            onClick={handleButtonClick}
            className="ml-4 bg-white text-black hover:bg-zinc-200 transition-colors duration-200 ease-in-out rounded-full px-4 py-1 text-sm"
          >
            {isSignedIn ? "Go to Topics" : "Sign Up"}
          </Button>
        </div>
      )}

      <Dialog open={showLimitReachedPopup} onOpenChange={setShowLimitReachedPopup}>
        <DialogContent className="sm:max-w-[425px] bg-black/90 backdrop-blur-xl text-white border border-zinc-800 rounded-none shadow-2xl">
          <DialogHeader>
            <DialogTitle className="text-2xl font-bold">Free Usage Limit Reached</DialogTitle>
            <DialogDescription className="text-zinc-400 mt-2">
              You've reached the maximum number of free uses. Sign up now to unlock unlimited access!
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-end space-x-4 mt-8">
            <Button 
              variant="outline" 
              onClick={handleLogIn}
              disabled={isSignInLoading}
              className={cn(
                "bg-transparent hover:bg-zinc-800 text-white border-zinc-700",
                "transition-all duration-200 ease-in-out rounded-none px-6 py-2",
                isSignInLoading ? "opacity-50" : "hover:scale-105"
              )}
            >
              {isSignInLoading ? "Signing In..." : "Sign In"}
            </Button>
            <Button 
              onClick={handleSignUp}
              disabled={isSignUpLoading}
              className={cn(
                "bg-white text-black hover:bg-zinc-200",
                "transition-all duration-200 ease-in-out rounded-none px-6 py-2",
                isSignUpLoading ? "opacity-50" : "hover:scale-105"
              )}
            >
              {isSignUpLoading ? "Signing Up..." : "Sign Up"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Add these keyframes to your global CSS */}
      <style jsx global>{`
        @keyframes moveRight {
          from { transform: translateX(-100%); }
          to { transform: translateX(200%); }
        }
        @keyframes moveLeft {
          from { transform: translateX(200%); }
          to { transform: translateX(-100%); }
        }
        .animate-pulse-slow {
          animation: pulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }
      `}</style>
    </div>
  );
};

export default ConversationPreview;
