import React from "react";
import Link from "next/link";
import { FaXTwitter, FaLinkedin, FaTiktok } from "react-icons/fa6";

// Define types for better type safety and documentation
type FooterLink = {
  href: string;
  label: string;
};

type FooterSection = {
  title: string;
  links: FooterLink[];
};

// Move data outside component to prevent recreating on each render
const FOOTER_SECTIONS: FooterSection[] = [
  {
    title: "Company",
    links: [
      { href: "/pages/about", label: "About" },
      { href: "/pages/melo-philosophy", label: "Melo Philosophy" },
    ],
  },
  {
    title: "Legal",
    links: [
      { href: "/pages/privacy-policy", label: "Privacy Policy" },
      { href: "/pages/terms-of-service", label: "Terms of Service" },
    ],
  },
  {
    title: "Contact",
    links: [
      { href: "/pages/contact", label: "Contact Us" },
      { href: "/sign-in", label: "Sign In" },
    ],
  },
] as const;

// Add social media links
const SOCIAL_LINKS = [
  { 
    href: "https://x.com/EsmaeilSafaee", 
    label: "X", 
    icon: <FaXTwitter className="w-5 h-5" /> 
  },
  { 
    href: "https://www.linkedin.com/company/meloinc", 
    label: "LinkedIn", 
    icon: <FaLinkedin className="w-5 h-5" /> 
  },
  { 
    href: "https://www.tiktok.com/@melo.ai", 
    label: "TikTok", 
    icon: <FaTiktok className="w-5 h-5" /> 
  },
] as const;

// Extract reusable components for better organization
const FooterSection = ({ title, links }: FooterSection) => (
  <div className="animate-fade-in text-center">
    <h3 className="font-semibold mb-5 text-gray-200 uppercase tracking-wider text-sm">
      {title}
    </h3>
    <ul className="space-y-3.5">
      {links.map((link) => (
        <li key={link.href}>
          <Link 
            href={link.href} 
            className="text-gray-400 transition-all duration-300 hover:text-white hover:underline"
          >
            {link.label}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="bg-black text-white mt-20">
      <div className="border-t border-gray-800/50" />
      
      <div className="max-w-4xl mx-auto px-6 py-20">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8 gap-y-14 justify-items-center">
          {FOOTER_SECTIONS.map((section) => (
            <FooterSection key={section.title} {...section} />
          ))}
        </div>
        
        <div className="mt-20 flex justify-center space-x-8">
          {SOCIAL_LINKS.map((social) => (
            <a
              key={social.href}
              href={social.href}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-white transition-all duration-300 hover:scale-110"
              aria-label={social.label}
            >
              {social.icon}
            </a>
          ))}
        </div>

        <div className="mt-10 text-center text-sm text-gray-400">
          © {currentYear} Melo. All rights reserved. 
          <span className="block sm:inline sm:ml-1">San Francisco, CA, USA</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
